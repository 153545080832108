import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'tfc-components';

import Container from '../Container';

import logoP from 'assets/images/logo-white.svg';

export interface FooterProps {
  logo?: string;
  menus: MenuItem[];
  socials: {
    icon: string;
    link: string;
  }[];
  hyperlinks: MenuItem[]
}

const Footer: React.FC<FooterProps> = ({
  logo, menus, socials, hyperlinks
}) => (
  <div className="o-footer">
    <Container>
      <div className="o-footer_wrapper">
        <Link className="o-footer_logo" to="/">
          <Image
            imgSrc={logo ?? logoP}
            alt="Evol AI Tutor"
            ratio={139 / 48}
          />
        </Link>
        <div className="o-footer_menu">
          {menus.map((menu) => (
            <Link
              key={menu.id}
              className="o-footer_navItem"
              to={menu.reference?.slug || menu.link || '#'}
              target={menu.target}
            >
              {menu.reference?.text || menu.title}
            </Link>
          ))}
        </div>
        <div className="o-footer_socials">
          {socials.map((social) => (
            <a
              key={social.icon}
              href={social.link}
              target="_blank"
              rel="noreferrer"
              className="o-footer_social"
            >
              <Image imgSrc={social.icon} alt="Social" ratio={1} />
            </a>
          ))}
        </div>
      </div>
      <div className="o-footer_hyperlinks">
        {hyperlinks.map((hyperlink) => (
          <Link
            key={hyperlink.id}
            to={hyperlink.reference?.slug || hyperlink.link || '#'}
            target={hyperlink.target}
            className="o-footer_hyperlink"
          >
            {hyperlink.reference?.text || hyperlink.title}
          </Link>
        ))}
      </div>
    </Container>
  </div>
);

export default Footer;
