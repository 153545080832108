import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getProfileService } from 'services/auth';
import { ProfileDataType } from 'services/auth/types';
import { removeAccessToken, removeRefreshAccessToken } from 'services/common/storage';

interface AuthState {
  profile?: ProfileDataType;
}

const initialState: AuthState = {
};

export const getProfileAction = createAsyncThunk<ProfileDataType>(
  'profileReducer/getProfile',
  async (_, { rejectWithValue }) => {
    try {
      return await getProfileService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const profileSlice = createSlice({
  name: 'profileReducer',
  initialState,
  reducers: {
      resetAuth($state) {
      $state.profile = undefined;
      removeAccessToken();
      removeRefreshAccessToken();
    },
  },
  extraReducers(builder) {
    builder.addCase(getProfileAction.fulfilled, ($state, action) => {
      $state.profile = action.payload;
    });
  },
});

export const {
  resetAuth
} = profileSlice.actions;

export default profileSlice.reducer;
