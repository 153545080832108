import {
  DeleteParamsTypes,
  MoMoDataTypes,
  MoMoParamsTypes,
  OrderDataTypes,
  OrderParamsTypes,
  OrderResponseTypes,
  PayPalLinkParamsTypes,
  PaymentCardTypes,
  StripePaymentIntentParams,
  StripePaymentParams,
  StripePaymentResponse,
  TVerifyPromotionCode
} from './types';

import axiosInstance from 'services/common/instance';

export const postCreateOrderService = async (params: OrderParamsTypes): Promise<OrderDataTypes> => {
  const res = await axiosInstance.post('orders', params);
  return res.data.data;
};

export const getPaymentCardPayPalService = async (params: OrderDataTypes)
  : Promise<PaymentCardTypes> => {
  const res = await axiosInstance.get(`payments/cards?orderId=${params.orderId}`);
  return res.data;
};

export const getOrderService = async (params: RequestParamsTypes):
  Promise<APIPagination<OrderResponseTypes[]>> => {
  const res = await axiosInstance.get('orders', { params });
  return res.data;
};

export const getPayPalLinkService = async (params: PayPalLinkParamsTypes)
  : Promise<{ link: string }> => {
  const res = await axiosInstance.get('payments', { params });
  return res.data.data;
};

export const postPaymentSuccessService = async (params: { token: string }): Promise<void> => {
  const res = await axiosInstance.post(`payments/success?token=${params.token}`);
  return res.data;
};

export const deleteOrderByIdService = async (
  params: DeleteParamsTypes
) => axiosInstance.delete('orders', { data: params });

export const paymentMoMoService = async (
  params: MoMoParamsTypes
): Promise<MoMoDataTypes> => {
  const res = await axiosInstance.post('payments/momo', params);
  return res.data.data;
};

export const verifyPromotionCodeService = async (data: {
  orderId: number,
  code: string
}): Promise<APIResponse<TVerifyPromotionCode>> => {
  const res = await axiosInstance.post('orders/promotion-program-codes/verify-code', data);
  return res.data;
};

export const removePromotionCodeService = async (data: {
  orderId: number,
}): Promise<APIResponse<TVerifyPromotionCode>> => {
  const res = await axiosInstance.post('orders/promotion-program-codes/remove-code', data);
  return res.data;
};

export const stripePaymentService = async (
  data: StripePaymentParams
): Promise<StripePaymentResponse> => {
  const res = await axiosInstance.post('stripe-payment', data);
  return res.data.data;
};

export const stripeCreatePaymentIntentService = async (
  data: StripePaymentIntentParams
): Promise<string> => {
  const res = await axiosInstance.post('stripe-payment/payment-intent', data);
  return res.data.data.clientSecret;
};
