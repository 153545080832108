import {
  AuthResTypes, LoginSocialParamsTypes, PKCESocialDataType, ProfileDataType
} from './types';

import axiosInstance from 'services/common/instance';

export const getPKCESocialDataService = async (
  provider: LoginSocialType
): Promise<PKCESocialDataType> => {
  const response = await axiosInstance.post('students/login-social-pkce', { provider });
  return response.data.data;
};

export const loginSocialService = async (params: LoginSocialParamsTypes):
  Promise<AuthResTypes> => {
  const res = await axiosInstance.post('students/login-social', params);
  return res.data.data;
};

export const getProfileService = async (): Promise<ProfileDataType> => {
  const res = await axiosInstance.get('students/profile');
  return res.data.data;
};
