import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Image, Typography } from 'tfc-components';

import Container from '../Container';

import logoP from 'assets/images/logo.svg';
import usa from 'assets/images/usa_flag.svg';
import vietnam from 'assets/images/vietnam_flag.svg';
import useClickOutside from 'hooks/useClickOutside';
import i18n from 'i18n';
import { getPath, getStaticPath } from 'routes';
import { setLocalStorage } from 'services/common/storage';
import { resetAuth } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLanguage } from 'store/system';
import { LOCAL_STORAGE } from 'utils/constants';
import mapModifiers from 'utils/functions';

export interface HeaderProps {
  logo?: string;
  menus: MenuItem[];
}

const Header: React.FC<HeaderProps> = ({ logo, menus }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const profile = useAppSelector((state) => state.auth.profile);
  const language = useAppSelector((state) => state.systems.language);
  const profileRef = useRef<HTMLDivElement>(null);

  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 991);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isDropdown, setIsDropdown] = React.useState(false);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLocalStorage(LOCAL_STORAGE.LANGUAGE, lang);
    dispatch(setLanguage(lang));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
      if (window.innerWidth >= 991) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  useClickOutside(profileRef, () => {
    setIsDropdown(false);
  });

  const handleLogout = () => {
    dispatch(resetAuth());
    navigate(getStaticPath('HOME', language));
  };

  return (
    <header className="o-header">
      <Container>
        <div className="o-header_wrapper">
          <Link className="o-header_logo" to="/">
            <Image imgSrc={logo ?? logoP} alt="Evol AI Tutor" ratio={139 / 48} />
          </Link>

          <div className={mapModifiers('o-header_nav', isMenuOpen && 'active')}>
            <div className="o-header_menu">
              {menus.map((menu) => (
                <Link
                  key={menu.id}
                  className="o-header_navItem"
                  to={menu.reference?.slug || menu.link || '#'}
                  target={menu.target}
                >
                  {menu.reference?.text || menu.title}
                </Link>
              ))}
            </div>
            <div className="o-header_controls">
              <Button extendClasses="btn-primary btn-h44" onClick={() => navigate(getPath(language || 'vi', '/plan'))}>
                <Typography.Text fontweight="600">Premium plan</Typography.Text>
              </Button>
              {window.innerWidth < 991 ? (
                <Button extendClasses="btn-outline btn-h44" onClick={() => changeLanguage(language === 'vi' ? 'en' : 'vi')}>
                  <Typography.Text fontweight="600">{language === 'vi' ? 'Tiếng Việt' : 'English'}</Typography.Text>
                </Button>
              ) : (
                <div className="o-header_controls_wrap" onClick={() => changeLanguage(language === 'vi' ? 'en' : 'vi')}>
                  <div className="o-header_controls_language">
                    <Image
                      imgSrc={language === 'vi' ? vietnam : usa}
                      alt="flag"
                      ratio={1}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {profile ? (
            <div ref={profileRef} className="o-header_dropdownList">
              {/* {window.innerWidth >= 991 && ( */}
              <div
                className={mapModifiers('o-header_profile', profile.productData?.userLevel?.name && 'premium')}
                onClick={() => setIsDropdown((prev) => !prev)}
              >
                <span className="o-header_profile_avatar">
                  {profile.name?.split(' ')[0]?.charAt(0) || ''}
                </span>
                {profile.productData?.userLevel?.name && (
                  <div className="o-header_profile_levelTag">
                    {profile.productData?.userLevel?.name}
                  </div>
                )}
              </div>
              {/* )} */}
              {isDropdown && (
                <div className="o-header_dropdownList_popup">
                  <Button
                    extendClasses="btn-primary btn-h44"
                    onClick={handleLogout}
                  >
                    <Typography.Text fontweight="600">
                      Log out
                    </Typography.Text>
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <Button
              extendClasses="btn-outline btn-h44"
              onClick={() => navigate(getStaticPath('LOGIN', language))}
            >
              <Typography.Text fontweight="600">{t('system.login')}</Typography.Text>
            </Button>
          )}
          {isMobile && (
            <div
              className={mapModifiers('o-header_hamburger', isMenuOpen && 'active')}
              onClick={() => setIsMenuOpen((prev) => !prev)}
            >
              <div />
              <div />
              <div />
            </div>
          )}
        </div>
      </Container>
    </header>
  );
};

export default Header;
