import { MenuDataType } from './types';

import axiosInstance from 'services/common/instance';

export const getMenusService = async (): Promise<MenuDataType[]> => {
  const res = await axiosInstance.get('menus');
  return res.data?.data;
};

export const placeholder = '';
