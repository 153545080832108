/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Loading } from 'tfc-components';

import RenderPage from './RenderPage';

import { getPageService } from 'services/pages';
import { useAppDispatch } from 'store/hooks';
import { setPageData } from 'store/system';

const PageNavigate: React.FC = () => {
  const dispatch = useAppDispatch();
  const { slug } = useParams<{ slug: string }>();

  const {
    data,
    isLoading,
    error,
  } = useQuery(
    ['getPage', slug],
    () => getPageService(slug || ''),
    {
      onSuccess: (pageData) => {
        dispatch(setPageData(pageData));
      },
      enabled: !!slug,
    },
  );

  if (isLoading && slug) {
    return <div className="loading-fullscreen"><Loading.CircleDashed width={32} /></div>;
  }

  if (error && (error as ErrorSystemType).response.status) {
    return <>Error</>;
  }

  return <RenderPage pageData={data as any} />;
};

export default PageNavigate;
