export const ERROR_CODE_SUPPORTED: GlobalErrorCode[] = [400, 403, 404, 429, 500];
export const LOCAL_STORAGE = {
  ACCESS_TOKEN: 'EVOL-ACCESS-TOKEN',
  REFRESH_TOKEN: 'EVOL-REFRESH-TOKEN',
  SOCIAL_PKCE_UUID: 'SOCIAL_PKCE_UUID',
  SOCIAL_PKCE_STATE: 'SOCIAL_PKCE_STATE',
  ORDER_ID: 'ORDER_ID',
  LANGUAGE: 'EVOL_LANGUAGE',
};
export type ConstantRoutesCodes = keyof typeof CONSTANT_ROUTES;

export const CONSTANT_ROUTES: { [key: string]: { [lang: string]: string } } = {
  // PLAN: {
  //   vi: '/plan',
  //   en: '/plan'
  // },
  // ABOUT_US: {
  //   vi: '/ve-chung-toi',
  //   en: '/about-us'
  // },
  LOGIN: {
    vi: '/dang-nhap',
    en: '/login'
  },
  LOGIN_SOCIAL: {
    vi: '/social-login',
    en: '/social-login'
  },
  PAYMENT: {
    vi: '/thanh-toan',
    en: '/payment'
  },
  PAYMENT_RESULT: {
    vi: '/ket-qua-thanh-toan',
    en: '/result-payment'
  }
};

export const COLORS = {
  deepCarotOrange: '#e76f34',
  arsenic: '#434343'
};

export const SOCIAL_LOGIN_HOST = {
  GOOGLE: 'https://accounts.google.com/o/oauth2/v2/auth',
  TWITTER: 'https://twitter.com/i/oauth2/authorize',
  LINKEDIN: 'https://www.linkedin.com/oauth/v2/authorization',
  MICROSOFT: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  FACEBOOK: 'https://www.facebook.com/v20.0/dialog/oauth',
  APPLE: 'https://appleid.apple.com/auth/authorize'
};

export const PRODUCT_DURATION_NAME = {
  7: 'system.week',
  30: 'system.month',
  90: 'system.3_months',
  180: 'system.6_months',
  365: 'system.year',
};

export const RESULT_CODE_MOMO: { [key: number]: string } = {
  0: 'Success',
  9000: 'Transaction has been successfully confirmed',
  8000: 'Transaction is pending user confirmation for payment again',
  7000: 'Transaction is being processed',
  7002: 'Transaction is being processed by the payment provider',
  1000: 'Transaction has been created, awaiting user payment confirmation',
  11: 'Access denied',
  12: 'API version is not supported for this request',
  13: 'Business authentication failed',
  20: 'Request has an invalid format',
  22: 'Invalid transaction amount',
  40: 'Duplicate RequestId',
  41: 'Duplicate OrderId',
  42: 'Invalid or not found OrderId',
  43: 'Request denied due to conflict during transaction processing',
  44: 'Transaction denied due to invalid payment code',
  1001: 'Payment transaction failed due to insufficient user balance',
  1002: 'Transaction denied by the payment account issuer',
  1003: 'Transaction has been canceled',
  1004: 'Transaction failed due to payment amount exceeding user limit',
  1005: 'Transaction failed due to expired URL or QR code',
  1006: 'Transaction failed due to user refusing to confirm payment',
  1007: 'Transaction denied because the account does not exist or is inactive',
  1026: 'Transaction restricted by promotion program rules',
  1030: 'Payment order failed due to invalid information',
  1080: 'Refund transaction denied. Initial payment transaction not found',
  1081: 'Refund transaction denied. Initial payment may have already been refunded',
  2001: 'Transaction failed due to incorrect link information',
  2007: 'Transaction failed due to temporarily locked link',
  3001: 'Link failed because the user refused confirmation',
  3002: 'Link denied due to violating link rules',
  3003: 'Link cancellation denied due to exceeding cancellation limit',
  3004: 'Link cannot be canceled due to pending transactions',
  4001: 'Transaction restricted because the user has not completed account verification',
  4010: 'OTP verification failed',
  4011: 'OTP not sent or expired',
  4100: 'Transaction failed due to unsuccessful user login',
  4015: '3DS verification failed',
  10: 'System under maintenance',
  99: 'Unknown error',
};

export const RESULT_CODE_STRIPE: { [key: number]: string } = {
  1: 'Payment is pending',
  7: 'Payment is failed',
  13: 'Payment is successful'
};
