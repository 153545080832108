import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Typography } from 'tfc-components';

interface PromotionalCodeInputProps {
  id: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  isPayment?: boolean;
}

const PromotionalCodeInput: React.FC<PromotionalCodeInputProps> = ({
  id,
  name,
  onChange,
  value,
  isPayment
}: PromotionalCodeInputProps) => {
  const { t } = useTranslation();

  return (
    <div className="o-promoCodeInput">
      <div className="o-promoCodeInput_information">
        <Typography.Text extendClasses="color-jet fs-14x21" fontweight="500">
          {isPayment ? t('system.promotionalCode') : t('system.activeCode')}
        </Typography.Text>
        <div className="o-promoCodeInput_input">
          <Input placeholder={`${isPayment ? '0000 0000 0000' : t('system.typeCode')}`} id={id} name={name} onChange={onChange} value={value} />
        </div>
      </div>
      <div className="o-promoCodeInput_button">
        {isPayment ? (
          <Button>
            <Typography.Text
              fontweight="500"
              extendClasses="color-giants-orange fs-16x20"
            >
              {t('system.apply')}
            </Typography.Text>
          </Button>
        ) : (
          <Button extendClasses="btn-h44 o-promoCodeInput_btnDisabled">
            <Typography.Text
              fontweight="500"
              extendClasses="color-giants-orange fs-16x20"
            >
              {t('system.unlockPremium')}
            </Typography.Text>
          </Button>
        )}
      </div>
    </div>
  );
};

export default PromotionalCodeInput;
