import React from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Loading } from 'tfc-components';

import Container from 'components/organisms/Container';
import useDidMount from 'hooks/useDidMount';
import { loginSocialService } from 'services/auth';
import { getLocalStorage, setAccessToken, setRefreshToken } from 'services/common/storage';
import { getProfileAction } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CONSTANT_ROUTES, LOCAL_STORAGE } from 'utils/constants';

const LoginSocial: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const language = useAppSelector((state) => state.systems.language);
  const { state: stateLocation } = useLocation();
  const statePath = stateLocation as { path?: string };
  const provider = searchParams.get('provider') as LoginSocialType;
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  const { mutate: handleLoginSocial, isLoading } = useMutation(
    ['loginSocialMutate'],
    loginSocialService,
    {
      onSuccess(data) {
        const { accessToken, refreshToken } = data;
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        dispatch(getProfileAction()).unwrap().then(() => {
          localStorage.removeItem(LOCAL_STORAGE.SOCIAL_PKCE_STATE);
          localStorage.removeItem(LOCAL_STORAGE.SOCIAL_PKCE_UUID);
          navigate(statePath?.path || '/', { replace: true });
        });
      },
      onError(error) {
        console.log({ error });

        alert('Login failed');
      }
    }
  );

  useDidMount(() => {
    if (code && provider) {
      const stateLocal = getLocalStorage(LOCAL_STORAGE.SOCIAL_PKCE_STATE);
      const uuid = getLocalStorage(LOCAL_STORAGE.SOCIAL_PKCE_UUID);
      if (stateLocal === state && uuid) {
        handleLoginSocial({
          payload: {
            code,
            redirectUri: `${window.location.origin}${!language || language === 'vi' ? '' : `/${language}`}${CONSTANT_ROUTES.LOGIN_SOCIAL.vi}?provider=${provider}`,
            uuid
          },
          provider,
        });
      }
    }
  });

  return (
    <div className="p-loginSocial">
      <Container fluid>
        {isLoading && <div className="loading-fullscreen"><Loading.CircleDashed width={50} /></div>}
      </Container>
    </div>
  );
};

export default LoginSocial;
