/* eslint-disable @typescript-eslint/no-unused-vars */
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { forwardRef, useImperativeHandle } from 'react';

export interface StripeCheckoutFormRef {
  handleSubmit: () => Promise<string | null>;
}

interface StripeCheckoutFormProps {
}

const StripeCheckoutForm = forwardRef<StripeCheckoutFormRef, StripeCheckoutFormProps>(
  ({ }, ref) => {
    const options = {
      style: {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
    };
    const stripe = useStripe();
    const elements = useElements();
    useImperativeHandle(ref, () => ({
      handleSubmit: async () => {
        if (stripe && elements) {
          const cardElement = elements.getElement(CardElement);
          if (!cardElement) {
            return null;
          }
          // Create a token using the card information
          const { token, error } = await stripe.createToken(cardElement);
          if (error) {
            return null;
          }
          if (token) {
            return token.id;
          }
          return null;
        }
        return null;
      }
    }));
    return (
      <CardElement
        className="sr-input sr-card-element p-payment_info"
        options={options}
      />
    );
  }
);

export default StripeCheckoutForm;
