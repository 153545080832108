import React, { useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import Footer, { FooterProps } from 'components/organisms/Footer';
import Header, { HeaderProps } from 'components/organisms/Header';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { useAppSelector } from 'store/hooks';

interface MainLayoutProps {
  children?: React.ReactNode;
}
const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { height } = useWindowDimensions();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const content = searchParams.get('content');
  const { header, footer, menus } = useAppSelector((state) => state.systems);

  const headerData: HeaderProps = useMemo(() => {
    const logo = header?.blocks.header.logo?.data.path;
    const menuHeader = menus?.find(
      (item) => item.code === header?.blocks.header.menu?.data
    );
    return {
      logo,
      menus: menuHeader
        ? menuHeader.items.map((item, index) => ({
          id: item.id || index,
          title: item.title,
          reference: {
            text: item.title ?? '',
            slug: item.reference?.slug ?? item.link ?? '',
          },
        }))
        : [],
    };
  }, [header, menus]);

  const footerData: FooterProps = useMemo(() => {
    const logo = footer?.blocks.footer.logo?.data.path;
    const menu = menus?.find(
      (item) => item.code === footer?.blocks.footer.menu?.data
    );
    const socials = footer?.blocks.footer.socials?.data.map((item) => ({
      icon: item.icon?.data.path ?? '',
      link: item.socialLink?.data.url ?? '',
    }));
    const hyperlinks = menus?.find(
      (item) => item.code === footer?.blocks.footer.secondMenu?.data
    );
    return {
      logo,
      menus: menu
        ? menu.items.map((item, index) => ({
          id: item.id || index,
          title: item.title,
          reference: {
            text: item.title ?? '',
            slug: item.reference?.slug ?? item.link ?? '',
          },
        }))
        : [],
      socials: socials || [],
      hyperlinks: hyperlinks
        ? hyperlinks.items.map((item, index) => ({
          id: item.id || index,
          title: item.title,
          reference: {
            text: item.title ?? '',
            slug: item.reference?.slug ?? item.link ?? '',
          },
        }))
        : [],
    };
  }, [footer, menus]);

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [location.pathname]);

  if (content) {
    return children as JSX.Element;
  }

  return (
    <main className="t-mainLayout" style={{ minHeight: `${height}px` }}>
      <Header {...headerData} />
      <div className="t-mainLayout_body">{children}</div>
      <Footer {...footerData} />
    </main>
  );
};

export default MainLayout;
