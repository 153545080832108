import { lazy } from 'react';

const Home = lazy(() => import('pages/Home'));
const AboutUs = lazy(() => import('pages/AboutUs'));
const Plan = lazy(() => import('pages/Plan'));

export type TemplatePage =
  'HOME_PAGE'
  | 'PLAN_PAGE'
  | 'ABOUT_US_PAGE';

export type TemplateCodeType = {
  templateCode: TemplatePage;
  component: React.FC<any>;
};

export const TemplateCodes: TemplateCodeType[] = [
  {
    templateCode: 'HOME_PAGE',
    component: Home,
  },
  {
    templateCode: 'ABOUT_US_PAGE',
    component: AboutUs,
  },
  {
    templateCode: 'PLAN_PAGE',
    component: Plan,
  },
];
