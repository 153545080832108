import React from 'react';
import { Typography } from 'tfc-components';

import mapModifiers from 'utils/functions';

interface ModalProps {
  children: React.ReactNode;
  onClose: () => void;
}

interface ModalComposition {
  Header: React.FC<{ children: React.ReactNode }>;
  Body: React.FC<{ children: React.ReactNode }>;
  Footer: React.FC<{ children: React.ReactNode }>;
}

const Modal: React.FC<ModalProps> & ModalComposition = ({ children, onClose }) => {
  const [isClosing, setIsClosing] = React.useState(false);

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'unset';
    };
  }, []);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return (
    <div className={mapModifiers('o-modal', isClosing && 'closing')}>
      <div className="o-modal_wrapper" aria-modal aria-hidden tabIndex={-1}>
        {children}
      </div>
      <div className="o-modal_overlay" onClick={handleClose} />
    </div>
  );
};

Modal.Header = ({ children }) => (
  <div className="o-modal_header">
    <Typography.Heading type="h3" fontweight="600" extendClasses="fs-24x36 text-center">
      {children}
    </Typography.Heading>
  </div>
);

Modal.Body = ({ children }) => <div className="o-modal_body">{children}</div>;

Modal.Footer = ({ children }) => <div className="o-modal_footer">{children}</div>;

export default Modal;
