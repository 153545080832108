import React, { lazy, useMemo } from 'react';

import PageNavigate from 'navigations/PageNavigate';
// import AboutUs from 'pages/AboutUs';
import Login from 'pages/Login';
import LoginSocial from 'pages/LoginSocial';
import Payment from 'pages/Payment';
import PaymentResult from 'pages/PaymentResult';
// import Plan from 'pages/Plan';
import { useAppSelector } from 'store/hooks';
import { CONSTANT_ROUTES, ConstantRoutesCodes } from 'utils/constants';

const HomeNav = lazy(() => import('navigations/HomeNavigate'));

export const getPath = (lang: string, path: string) => `${lang === 'vi' ? path : `/${lang}${path}`}`;

export const getStaticPath = (code: ConstantRoutesCodes, lang?: string) => `${lang === 'vi'
  ? CONSTANT_ROUTES[code][lang] : `/${lang}${CONSTANT_ROUTES[code][lang || 'vi']}`}`;

type RouteProps = {
  path: string;
  element: JSX.Element;
};

const useRoutesList = () => {
  const locales = useAppSelector((state) => state.systems.system?.locales);
  const routes: RouteProps[] = useMemo(() => {
    if (!locales) return [];
    const homeRoutes = Object.keys(locales).map((lang) => ({
      path: `${getPath(lang, '/')}`,
      element: <HomeNav />
    }));
    const pageRoutes = Object.keys(locales).map((lang) => ({
      path: `${getPath(lang, '/:slug')}`,
      element: <PageNavigate />
    }));
    return [
      homeRoutes,
      // Object.keys(locales).map((lang) => ({
      //   path: `${getStaticPath('PLAN', lang)}`,
      //   element: <Plan />
      // })),
      // Object.keys(locales).map((lang) => ({
      //   path: `${getStaticPath('ABOUT_US', lang)}`,
      //   element: <AboutUs />
      // })),
      Object.keys(locales).map((lang) => ({
        path: `${getStaticPath('LOGIN', lang)}`,
        element: <Login />
      })),
      Object.keys(locales).map((lang) => ({
        path: `${getStaticPath('LOGIN_SOCIAL', lang)}`,
        element: <LoginSocial />
      })),
      Object.keys(locales).map((lang) => ({
        path: `${getStaticPath('PAYMENT_RESULT', lang)}`,
        element: <PaymentResult />
      })),
      Object.keys(locales).map((lang) => ({
        path: `${getStaticPath('PAYMENT', lang)}`,
        element: <Payment />
      })),
      pageRoutes,
    ].flat();
  }, [locales]);
  return routes;
};

export default useRoutesList;
