const returnErrorMessages = (error: ValidateError) => {
  switch (error.code) {
    case 'invalidPayment':
      return 'system.errorMessageInvalidPayment';
    case 400:
      return 'system.errorMessageOver1000';
    case 404:
      return 'system.errorEntityNotFound';
    default:
      return 'system.errorMessageGeneral';
  }
};

export default returnErrorMessages;
