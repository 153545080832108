import { useEffect } from 'react';
import ReactGA4 from 'react-ga4';

import { useAppSelector } from 'store/hooks';

const useGaTracker = () => {
  const gaIds = useAppSelector((state) => state.systems.system?.others?.gaIds);

  useEffect(() => {
    if (gaIds && gaIds.length > 0) {
      gaIds.forEach((ga) => {
        if (ga) {
          ReactGA4.initialize(ga);
          ReactGA4.gtag('config', ga);
        }
      });
      ReactGA4.gtag('js', new Date());
    }
  }, [gaIds]);
};

export default useGaTracker;
