import React from 'react';

import mapModifiers from 'utils/functions';

interface ContainerProps {
  fluid?: boolean;
  children?: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ fluid, children }) => (
  <div className={mapModifiers('o-container', fluid && 'fluid')}>
    {children}
  </div>
);

export default Container;
