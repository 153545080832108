import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  tickOrange: 'tickOrange',
  tickIndigo: 'tickIndigo',
  momo: 'momo',
  visa: 'visa',
  paypal: 'paypal',
  arrowDown: 'arrowDown',
  protect: 'protect',
  google: 'google',
  facebook: 'facebook',
  apple: 'apple',
  arrowRight: 'arrowRight',
  user: 'user',
  crown: 'crown',
  stripe: 'stripe',
  done: 'done',
  success: 'success',
  chevronOrange: 'chevronOrange',
};

export type IconName = keyof typeof iconList;

export type IconSize = '16' | '18' | '20' | '24' | '28' | '30' | '32' | '36' | '48' | '64' | 'full';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ iconName, size, className }) => (
  <i className={`${mapModifiers('a-icon', iconName, size)} ${className || ''}`} />
);

Icon.defaultProps = {
  size: undefined,
};

export default Icon;
