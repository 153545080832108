/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  Button, Image, Loading, Typography
} from 'tfc-components';

import logo from 'assets/images/logo.svg';
import Icon from 'components/atoms/Icon';
import Container from 'components/organisms/Container';
import { getPKCESocialDataService } from 'services/auth';
import { setLocalStorage } from 'services/common/storage';
import { useAppSelector } from 'store/hooks';
import {
  COLORS, CONSTANT_ROUTES, LOCAL_STORAGE, SOCIAL_LOGIN_HOST
} from 'utils/constants';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [provider, setProvider] = React.useState<LoginSocialType>();
  const language = useAppSelector((state) => state.systems.language);
  const { mutate: getPCKESocial, isLoading: isLoadingPCKESocial } = useMutation(
    'getPCKESocialMutate',
    getPKCESocialDataService,
    {
      onSuccess(data, variables) {
        const pkceState = Math.random().toString(36).slice(2);
        setLocalStorage(LOCAL_STORAGE.SOCIAL_PKCE_UUID, data?.uuid);
        setLocalStorage(LOCAL_STORAGE.SOCIAL_PKCE_STATE, pkceState);
        const params = {
          response_type: data.responseType,
          client_id: data.clientId,
          code_challenge: data.codeChallenge,
          code_challenge_method: data.codeChallengeMethod,
          state: pkceState,
        };

        const googleParams = {
          ...params,
          redirect_uri: `${window.location.origin}${!language || language === 'vi' ? '' : `/${language}`}${CONSTANT_ROUTES.LOGIN_SOCIAL[language ?? 'vi']}?provider=google`,
          scope: `${data.scopes.join(' ')}`,
          prompt: 'select_account',
          include_granted_scopes: 'true',
        };

        const fbParams = {
          ...params,
          redirect_uri: `${window.location.origin}${!language || language === 'vi' ? '' : `/${language}`}${CONSTANT_ROUTES.LOGIN_SOCIAL[language ?? 'vi']}?provider=facebook`,
          scope: `${data.scopes.join(' ')}`,
        };

        const appleParams = {
          ...params,
          redirect_uri: `${window.location.origin}${!language || language === 'vi' ? '' : `/${language}`}${CONSTANT_ROUTES.LOGIN_SOCIAL[language ?? 'vi']}?provider=apple`,
          scope: `${data.scopes.join(' ')}`,
        };

        switch (variables) {
          case 'google':
            window.location.replace(`${SOCIAL_LOGIN_HOST.GOOGLE}?${new URLSearchParams(googleParams).toString()}`);
            break;
          case 'facebook':
            window.location.replace(`${SOCIAL_LOGIN_HOST.FACEBOOK}?${new URLSearchParams(fbParams).toString()}`);
            break;
          case 'apple':
            window.location.replace(`${SOCIAL_LOGIN_HOST.APPLE}?${new URLSearchParams(appleParams).toString()}`);
            break;
          default:
            break;
        }
      },
      onError(error) {
        console.log('error', error);
      }
    }
  );
  const loginSocial = (prv: LoginSocialType) => {
    setProvider(prv);
    getPCKESocial(prv);
  };
  return (
    <div className="p-login">
      <Container fluid>
        <div className="p-login_form">
          <Image extendClasses="p-login_image" imgSrc={logo} alt="logo" ratio={210 / 72} />
          <Typography.Heading type="h4" extendClasses="fs-24x36 color-smoky-black p-login_title" fontweight="600" textStyle="center">{t('system.logInToEvol')}</Typography.Heading>
          <Typography.Text extendClasses="fs-16x24 color-dim-gray" textStyle="center">
            {t('system.byAgree')}
            {' '}
            <Typography.Text type="span" fontweight="600" extendClasses="color-smoky-black">{t('system.termsOfService')}</Typography.Text>
            {' '}
            {t('system.andOur')}
            {' '}
            <Typography.Text type="span" fontweight="600" extendClasses="color-smoky-black">{t('system.privacyPolicy')}</Typography.Text>
            {' '}
            {language === 'vi' ? 'của chúng tôi' : ''}
            .
          </Typography.Text>
          <div className="p-login_action">
            <Button extendClasses="p-login_btn" size="large" onClick={() => loginSocial('google')}>
              <Icon iconName="google" size="32" />
              <Typography.Text extendClasses="color-eerie-black fs-18x24" fontweight="600">Google</Typography.Text>
              {isLoadingPCKESocial && provider === 'google' ? <Loading.CircleDashed width={36} color={COLORS.arsenic} /> : <Icon iconName="chevronOrange" size="28" />}
            </Button>
            <Button extendClasses="p-login_btn" size="large" onClick={() => loginSocial('facebook')}>
              <Icon iconName="facebook" size="32" />
              <Typography.Text extendClasses="color-eerie-black fs-18x24" fontweight="600">Facebook</Typography.Text>
              {isLoadingPCKESocial && provider === 'facebook' ? <Loading.CircleDashed width={36} color={COLORS.arsenic} /> : <Icon iconName="chevronOrange" size="28" />}
            </Button>
            <Button extendClasses="p-login_btn" size="large" onClick={() => loginSocial('apple')}>
              <Icon iconName="apple" size="32" />
              <Typography.Text extendClasses="color-eerie-black fs-18x24" fontweight="600">Apple</Typography.Text>
              {isLoadingPCKESocial && provider === 'apple' ? <Loading.CircleDashed width={36} color={COLORS.arsenic} /> : <Icon iconName="chevronOrange" size="28" />}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Login;
