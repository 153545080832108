import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
 Button, Loading, Radio, Typography
} from 'tfc-components';

import StripeCheckoutForm, { StripeCheckoutFormRef } from './StripeCheckoutForm';

import Icon from 'components/atoms/Icon';
import Container from 'components/organisms/Container';
import Modal from 'components/organisms/Modal';
import PromotionalCodeInput from 'components/organisms/PromotionalCodeInput';
import returnErrorMessages from 'services/common/errors';
import { paymentMoMoService, postCreateOrderService, stripePaymentService } from 'services/orders';
import { MoMoParamsTypes, OrderParamsTypes } from 'services/orders/types';
import { getProductDetailService } from 'services/products';
import { useAppSelector } from 'store/hooks';
import {
 COLORS, CONSTANT_ROUTES, LOCAL_STORAGE, PRODUCT_DURATION_NAME
} from 'utils/constants';
import mapModifiers, {
  addAlpha, currencyFormat, dateCalculate, dateFormat
} from 'utils/functions';

const stripePromise = loadStripe(
  'pk_test_51Q8SLMRqjja03BsFnX7wnBW6vXTJAWnMvfuXK1ju9TcxTzugVNbOb8dch0Ft7smKbWG5llfDpScvKLGjqGc0Aaoq00PZAEAZZa'
);

const Payment: React.FC = () => {
  const navigate = useNavigate();
  const language = useAppSelector((state) => state.systems.language);
  const { t } = useTranslation();
  const [method, setMethod] = React.useState<string>('momo');
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const profile = useAppSelector((state) => state.auth.profile);

  // STATES
  const [orderData, setOrderData] = React.useState<{ orderId: number | null }>({ orderId: null });
  const [orderErrorModal, setOrderErrorModal] = React.useState(false);
  const [paymentErrorModal, setPaymentErrorModal] = React.useState(
    {
      isOpen: false,
      message: ''
    }
  );

  // REFS
  const stripeRef = useRef<StripeCheckoutFormRef>(null);

  // QUERIES
  const { data: productData, isLoading: loadingMain } = useQuery(
    'product',
    () => (id ? getProductDetailService(id) : undefined),
    {
      enabled: !!id
    }
  );
  const { mutate: paymentMoMoMutate, isLoading: isLoadingURLMomo } = useMutation(
    'payment-momo',
    (params: MoMoParamsTypes) => paymentMoMoService(params),
    {
      onSuccess: (data) => {
        window.location.replace(data.payUrl);
        localStorage.removeItem(LOCAL_STORAGE.ORDER_ID);
      },
      onError: (error: ErrorResponse[]) => {
        if (error.length > 0) {
          error.forEach((e) => setPaymentErrorModal({
              isOpen: true,
              message: t(returnErrorMessages(e)),
            }));
        }
        localStorage.removeItem(LOCAL_STORAGE.ORDER_ID);
      },
    }
  );
  const { mutate: paymentStripeMutate, isLoading: isLoadingStripe } = useMutation(
    'payment-stripe',
    stripePaymentService,
    {
      onSuccess: (data) => {
        navigate(
          `${!language || language === 'vi' ? '' : `/${language}`}${
            CONSTANT_ROUTES.PAYMENT_RESULT[language ?? 'vi']
          }?stripeCode=${data.status.value}`
        );
        localStorage.removeItem(LOCAL_STORAGE.ORDER_ID);
      },
      onError: () => setPaymentErrorModal({
        isOpen: true,
        message: t('payment.error.stripe'),
      })
    }
  );

  const { mutate: createOrderMutate } = useMutation(
    'create-order',
    async (params: OrderParamsTypes) => postCreateOrderService(params),
    {
      onSuccess: () => setOrderErrorModal(false),
      onError: (error: ErrorResponse[]) => {
        if (error.length > 0) {
          error.forEach((e) => setPaymentErrorModal({
              isOpen: true,
              message: e.message,
            }));
        }
      },
    }
  );

  const formattedPlanData = useMemo(() => {
    const currency = language === 'en' ? 'price' : 'priceVND';
    const hasDiscount = productData?.productPrice && productData?.productPrice.length > 1;
    const defaultPrice = productData?.productPrice.find((p) => p.isDefault)?.[currency] || 0;
    const discountPrice = hasDiscount
      ? productData?.productPrice.find((p) => !p.isDefault)?.[currency]
      : 0;

    return {
      ...productData,
      price: Number(hasDiscount ? discountPrice : defaultPrice),
      oldPrice: hasDiscount ? defaultPrice : undefined,
    };
  }, [productData, language]);

  // FUNCTIONS
  const handlePayment = async () => {
    if (!orderData.orderId) return;

    if (method === 'momo') {
      paymentMoMoMutate({
        redirectUrl: `${window.location.origin}${
          !language || language === 'vi' ? '' : `/${language}`
        }${CONSTANT_ROUTES.PAYMENT_RESULT[language ?? 'vi']}`,
        orderId: orderData.orderId,
      });
    } else if (method === 'credit') {
      const token = await stripeRef.current?.handleSubmit();
      if (token && formattedPlanData.price) {
        paymentStripeMutate({
          orderId: orderData.orderId,
          token,
          amount: formattedPlanData.price,
        });
      }
    }
  };

  const getStoredOrderId = () => localStorage.getItem(LOCAL_STORAGE.ORDER_ID);

  // EFFECTS
  useEffect(() => {
    const storedOrderId = getStoredOrderId();
    if (storedOrderId) {
      setOrderData({ orderId: Number(storedOrderId) });
    } else if (profile && id) {
      createOrderMutate(
        { productId: Number(id) },
        {
          onSuccess: (data) => {
            setOrderData({ orderId: data.orderId });
            localStorage.setItem(LOCAL_STORAGE.ORDER_ID, data.orderId.toString());
          },
        }
      );
    }
  }, [createOrderMutate, id, profile]);

  if (loadingMain) {
    return (
      <div className="loading-fullscreen">
        <Loading.CircleDashed width={32} color={COLORS.deepCarotOrange} />
      </div>
    );
  }

  if (!productData) return null;

  return (
    <div
      className="p-payment"
      style={{ '--cta-color': addAlpha(productData?.contentColor, 0.3) } as React.CSSProperties}
    >
      <Container fluid>
        <div className="p-payment_top">
          <div
            className="p-payment_title color-indigo fs-60x64"
            dangerouslySetInnerHTML={{ __html: 'Payment' }}
          />
          <div
            className="p-payment_description fs-16x24"
            dangerouslySetInnerHTML={{
              __html:
                'Evol is always here for you to check the accuracy of your answers, provide detailed feedback, correct solutions, and suggest similar or more advanced exercises to enhance your learning process',
            }}
          />
          <div className="p-payment_plan">
            <Typography.Text fontweight="600" extendClasses="fs-18x24">
              Premium
              {' '}
              {formattedPlanData.translations?.[language || 'vi']?.name || ''}
            </Typography.Text>
            <Typography.Text extendClasses="fs-16x20 p-payment_plan_limit">
              {t('system.premAccount')}
            </Typography.Text>
          </div>
          <div
            className="p-payment_plan_detail"
            style={{
              backgroundColor: addAlpha(productData.contentColor, 0.12),
            }}
          >
            <div className="p-payment_plan_item">
              <Typography.Text fontweight="600" extendClasses="color-jet fs-16x20">
                {t('system.total')}
              </Typography.Text>
              <Typography.Text
                fontweight="600"
                extendClasses="fs-16x20 color-giants-orange p-payment_plan_price"
              >
                {`${currencyFormat(formattedPlanData.price, language === 'vi' ? 'VND' : 'USD')}/${t(
                  PRODUCT_DURATION_NAME[formattedPlanData.duration ?? 30]
                )}`}
              </Typography.Text>
            </div>
            <div className="p-payment_plan_item">
              <Typography.Text extendClasses="color-jet fs-14x16">
                {t('system.nextBilling')}
              </Typography.Text>
              <Typography.Text extendClasses="color-jet fs-14x16">
                {dateFormat(
                  dateCalculate(
                    new Date(),
                    formattedPlanData.duration ?? 30,
                  ),
                  language,
                )}
              </Typography.Text>
            </div>
            <div className="p-payment_plan_description fs-14x16">
              <ul>
                <li>
                  {`${t('system.nextBillingOn')} ${dateFormat(
                    dateCalculate(new Date(), formattedPlanData.duration ?? 30),
                    language
                  )}`}
                </li>
                <li>{t('system.cancelAnytime')}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="p-payment_options">
          <Radio
            name="method"
            value="momo"
            extendClasses={mapModifiers('p-payment_radio', method === 'momo' && 'active')}
            onChange={() => setMethod('momo')}
          >
            <div className="p-payment_option">
              <div className="p-payment_option_holder" />
              <Typography.Text
                fontweight="600"
                extendClasses="color-smoky-black fs-16x24 p-payment_option_name"
              >
                {t('system.momoWallet')}
              </Typography.Text>
              <Icon iconName="momo" size="48" />
            </div>
          </Radio>
          <Radio
            name="method"
            value="credit"
            extendClasses={mapModifiers(
              'p-payment_radio',
              method === 'credit' && ['active', 'expand']
            )}
            onChange={() => setMethod('credit')}
          >
            <div className="p-payment_option">
              <div className="p-payment_option_holder" />
              <Typography.Text
                fontweight="600"
                extendClasses="color-smoky-black fs-16x24 p-payment_option_name"
              >
                Stripe
              </Typography.Text>
              <Icon iconName="visa" size="48" />
            </div>
            <Elements stripe={stripePromise}>
              <StripeCheckoutForm ref={stripeRef} />
            </Elements>
          </Radio>
          {/* <Radio
            name="method"
            value="paypal"
            extendClasses={mapModifiers('p-payment_radio', method === 'paypal' && 'active')}
            onChange={() => setMethod('paypal')}
          >
            <div className="p-payment_option">
              <div className="p-payment_option_holder" />
              <Typography.Text
                fontweight="600"
                extendClasses="color-smoky-black fs-16x24 p-payment_option_name"
              >
                PayPal
              </Typography.Text>
              <Icon iconName="paypal" size="48" />
            </div>
          </Radio> */}
          <div className="p-payment_promo">
            <PromotionalCodeInput id="promoCode" name="promoCode" isPayment />
          </div>
          <div className="p-payment_divider" />
          <Typography.Text extendClasses="fs-14x21 color-sonic-silver">
            {t('system.alwaysHere')}
          </Typography.Text>
          <div className="p-payment_confirm">
            <div className="p-payment_total">
              <Typography.Text extendClasses="fs-16x24 color-black" fontweight="600">
                {t('system.totalBill')}
                {': '}
                <Typography.Text
                  type="span"
                  fontweight="600"
                  extendClasses="fs-18x24 color-deep-carrot-orange"
                >
                  {!!(formattedPlanData.oldPrice
                    && (formattedPlanData.oldPrice > formattedPlanData.price))
                    && (
                      <Typography.Text
                        type="span"
                        extendClasses="color-ash-grey fs-16x20 p-payment_totalText"
                        textStyle="lineThrough"
                      >
                        {`${currencyFormat(
                          formattedPlanData.oldPrice,
                          language === 'vi' ? 'VND' : 'USD'
                        )}/${t(PRODUCT_DURATION_NAME[formattedPlanData.duration ?? '30'])}`}
                      </Typography.Text>
                    )}
                  <span className="p-payment_finalPrice">
                    {`${currencyFormat(
                      formattedPlanData.price,
                      language === 'vi' ? 'VND' : 'USD',
                    )}/${t(PRODUCT_DURATION_NAME[formattedPlanData.duration ?? '30'])} `}
                  </span>
                </Typography.Text>
              </Typography.Text>
            </div>
            <Button
              extendClasses="p-payment_confirm_button"
              onClick={handlePayment}
              loading={isLoadingURLMomo || isLoadingStripe}
              loadingIndicator={<Loading.CircleDashed width={24} color={COLORS.deepCarotOrange} />}
            >
              <Icon iconName="protect" size="24" />
              <Typography.Text extendClasses="color-white fs-16x24" fontweight="600">
                {t('system.continuePayment')}
              </Typography.Text>
            </Button>
          </div>
        </div>
      </Container>
      {orderErrorModal
        && createPortal(
          <Modal onClose={() => setOrderErrorModal(false)}>
            <Modal.Header>{t('system.errorTitle')}</Modal.Header>
            <Modal.Body>
              <Typography.Text extendClasses="fs-16x24 text-center">
                {t('system.errorMessage')}
              </Typography.Text>
            </Modal.Body>
            <Modal.Footer>
              <Button
                extendClasses="btn-secondary btn-h44 btn-wfull p-payment_modalBtn"
                onClick={() => setOrderErrorModal(false)}
              >
                <Typography.Text fontweight="600">{t('system.close')}</Typography.Text>
              </Button>
            </Modal.Footer>
          </Modal>,
          document.body
        )}
      {paymentErrorModal.isOpen
        && createPortal(
          <Modal onClose={() => setPaymentErrorModal({
            isOpen: false,
            message: ''
          })}
          >
            <Modal.Header>{t('system.paymentErrorTitle')}</Modal.Header>
            <Modal.Body>
              <Typography.Text extendClasses="fs-16x24 text-center">
                {paymentErrorModal.message || t('system.paymentErrorMessage')}
              </Typography.Text>
            </Modal.Body>
            <Modal.Footer>
              <Button
                extendClasses="btn-secondary btn-h44 btn-wfull p-payment_modalBtn"
                onClick={() => setPaymentErrorModal({
                  isOpen: false,
                  message: ''
                })}
              >
                <Typography.Text fontweight="600">{t('system.close')}</Typography.Text>
              </Button>
            </Modal.Footer>
          </Modal>,
          document.body
        )}
    </div>
  );
};

export default Payment;
