import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { getLocalStorage } from 'services/common/storage';
// import en from 'translations/en.json';
import { LOCAL_STORAGE } from 'utils/constants';

export const getCurrentLanguage = () => getLocalStorage(LOCAL_STORAGE.LANGUAGE) || 'vi';

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init({
    fallbackLng: 'vi',
    lng: getCurrentLanguage(),
    // resources: {
    //   vi: {
    //     translation: vi,
    //   },
    //   en: {
    //     translation: en
    //   }
    // },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_API_BASE_URL}fe-translations?locale={{lng}}`,
    },
    //! Only turn this defaultNS on when testing local static translation files
    // defaultNS: 'local',
  });

export default i18n;
