import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getMenusService } from 'services/menus';
import { getPageStaticAllService } from 'services/pages';
import { StaticAllData } from 'services/pages/types';
import {
  getHeaderFooterService,
  getGeneralService,
  getPageErrorService,
} from 'services/systems';
import {
  FooterDataTypes,
  GeneralDataTypes,
  HeaderDataTypes,
  HeaderFooterDataTypes,
  ErrorPageData
} from 'services/systems/types';

interface SystemState {
  footer?: FooterDataTypes;
  header?: HeaderDataTypes;
  system?: GeneralDataTypes;
  pageError?: ErrorPageData;
  menus?: MenuDataType[];
  language?: string;
  staticAll?: StaticAllData[];
  pageData?: BasePageDataTypes<any>
}

const initialState: SystemState = {
};

export const getHeaderFootersAction = createAsyncThunk<HeaderFooterDataTypes>(
  'systemReducer/getHeaderFooterAction',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getHeaderFooterService();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getMenusAction = createAsyncThunk<MenuDataType[]>(
  'systemReducer/getMenusAction',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getMenusService();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPageErrorAsync = createAsyncThunk<ErrorPageData>('systemReducer/getPageError', async (_, { rejectWithValue }) => {
  try {
    const response = await getPageErrorService();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
},);

export const getSystemAction = createAsyncThunk<GeneralDataTypes>(
  'systemReducer/getSystem',
  async (_, { rejectWithValue }) => {
    try {
      return await getGeneralService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getStaticAllAction = createAsyncThunk<StaticAllData[]>(
  'systemReducer/getStaticAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getPageStaticAllService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const systemSlice = createSlice({
  name: 'systemReducer',
  initialState,
  reducers: {
    setLanguage($state, action: PayloadAction<string>) {
      $state.language = action.payload;
    },
    setPageData($state, action: PayloadAction<BasePageDataTypes<any>>) {
      $state.pageData = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getHeaderFootersAction.fulfilled, ($state, action) => {
      $state.header = action.payload.header;
      $state.footer = action.payload.footer;
    });
    builder.addCase(getMenusAction.fulfilled, ($state, action) => {
      $state.menus = action.payload;
    });
    builder.addCase(getSystemAction.fulfilled, ($state, action) => {
      $state.system = action.payload;
    });
    builder.addCase(getPageErrorAsync.fulfilled, ($state, action) => {
      $state.pageError = action.payload;
    });
    builder.addCase(getStaticAllAction.fulfilled, ($state, action) => {
      $state.staticAll = action.payload;
    });
  },
});

export const {
  setLanguage,
  setPageData
} = systemSlice.actions;

export default systemSlice.reducer;
