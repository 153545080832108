import { createSlice } from '@reduxjs/toolkit';

import { ProductData } from 'services/products/types';

interface ExtendProductData extends ProductData {
  price?: number;
  oldPrice?: number;
}
interface ProductState {
  productData: ExtendProductData;
}

const initialState: ProductState = {
  productData: {
    id: 0,
    nameUnique: '',
    contentColor: '',
    duration: 7,
    userLevel: {
      name: '',
      requiredLogin: false,
      level: 0,
      config: {
        type: '',
        webOrWeekLimit: 0,
        appOrMonthLimit: 0,
      }
    },
    productPrice: [],
    translations: {},
    price: 0,
    oldPrice: 0,
  },
};

export const productSlice = createSlice({
  name: 'productReducer',
  initialState,
  reducers: {
    setCurrentProduct: ($state, action) => {
      $state.productData = action.payload;
    }
  }
});

export const { setCurrentProduct } = productSlice.actions;

export default productSlice.reducer;
