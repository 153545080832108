import React, { FunctionComponent } from 'react';

// eslint-disable-next-line import/no-cycle
import { TemplateCodes } from 'navigations';

export type RenderPageProps = {
  pageData?: BasePageDataTypes<any>;
};

const RenderPage: React.FC<RenderPageProps> = ({ pageData }) => {
  const Component = TemplateCodes.find(
    (template) => template.templateCode === pageData?.pageData.templateCode,
  )?.component;
  if (Component) {
    return React.createElement<any>(Component as FunctionComponent, pageData);
  }

  return null;
};

export default RenderPage;
